var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"slider",staticClass:"simple-slider",class:_vm.additionalClasses},[_c('div',{staticClass:"slider"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasNavigationButtons),expression:"hasNavigationButtons"}]},_vm._l((_vm.navigationButtons),function(button,key){return _c('ButtonIcon',{key:key,staticClass:"navigation-button",class:[
                    _vm.navigationButtonClass,
                    button.class,
                    ..._vm.verticalNavigationButtonClass,
                ],style:([_vm.navigationButtonsStyles]),attrs:{"disabled":button.isDisabled,"variant":_vm.BUTTON_VARIANT},nativeOn:{"click":function($event){return _vm.onNavigationButtonClick(button.direction)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(button.icon,{tag:"component"})]},proxy:true}],null,true)})}),1),_vm._v(" "),_c(_vm.tag,{ref:"wrapper",tag:"component",staticClass:"wrapper",class:[
                `items-count-${_vm.itemsCount}`,
                _vm.sliderClass,
                _vm.isScrolling ? 'is-scrolling' : '',
            ]},[_vm._t("slides")],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }