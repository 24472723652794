<template>
    <section ref="slider" class="simple-slider" :class="additionalClasses">
        <div class="slider">
            <div v-show="hasNavigationButtons">
                <ButtonIcon
                    v-for="(button, key) in navigationButtons"
                    :key="key"
                    :disabled="button.isDisabled"
                    :variant="BUTTON_VARIANT"
                    :class="[
                        navigationButtonClass,
                        button.class,
                        ...verticalNavigationButtonClass,
                    ]"
                    :style="[navigationButtonsStyles]"
                    class="navigation-button"
                    @click.native="onNavigationButtonClick(button.direction)"
                >
                    <template #default>
                        <component :is="button.icon" />
                    </template>
                </ButtonIcon>
            </div>

            <component
                :is="tag"
                ref="wrapper"
                class="wrapper"
                :class="[
                    `items-count-${itemsCount}`,
                    sliderClass,
                    isScrolling ? 'is-scrolling' : '',
                ]"
            >
                <slot name="slides" />
            </component>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';

import { THEMES } from '@types/MarketingSection';
import {
    SLIDER_ORIENTATIONS,
    ORIENTATION_VERTICAL,
    ORIENTATION_HORIZONTAL,
} from '@types/Slider';

import approximatelyEqual from '@assets/approximatelyEqual';
import { checkIfExistsInValuesMap } from '@assets/props';

import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';

import {
    ShevronDown,
    ShevronUp,
    ShevronRight,
    ShevronLeft,
} from '@eobuwie-ui/icons/v1/other';

const SCROLL_DEBOUNCE = 100;
const RESIZE_DEBOUNCE = 500;
const TOUCH_START_DEBOUNCE = 100;
const TOUCH_END_DEBOUNCE = 500;
const HIDE_SCROLL_AFTER = 1000;
const SCROLL_HEIGHT_EPSILON = 1; // Chrome reads scrollHeight + 1 for some values i.e. 382-388px

export default {
    name: 'SimpleSlider',

    components: {
        ButtonIcon,
        ShevronDown,
        ShevronUp,
        ShevronRight,
        ShevronLeft,
    },

    props: {
        tag: {
            type: String,
            default: 'ul',
        },

        hasScrollbar: {
            type: Boolean,
            default: true,
        },

        hasNavigationButtons: {
            type: Boolean,
            default: true,
        },

        itemsCount: {
            type: Number,
            default: 1,
        },

        additionalScrollOffset: {
            type: Number,
            default: 0,
        },

        navigationButtonsStyles: {
            type: Object,
            default: () => ({}),
        },

        navigationButtonClass: {
            type: String,
            default: '',
        },

        sliderClass: {
            type: String,
            default: '',
        },

        hasAutoPlay: {
            type: Boolean,
            default: false,
        },

        autoPlayDelay: {
            type: Number,
            default: 2000,
        },

        pauseAutoPlayOnMouseOver: {
            type: Boolean,
            default: false,
        },

        orientation: {
            type: String,
            default: ORIENTATION_HORIZONTAL,
            validator: checkIfExistsInValuesMap(SLIDER_ORIENTATIONS, true),
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        changeSlideByOne: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        slidesWidth: [],
        currentPosition: 0,
        wrapperScrollWidth: 0,
        wrapperVisibleWidth: 0,
        wrapperScrollHeight: 0,
        wrapperVisibleHeight: 0,
        observer: null,
        autoPlayInterval: null,
        slideWidth: 0,
        slideHeight: 0,
        slidesRef: null,
        singleSlide: null,
        isScrolling: false,
        isScrollingTimeout: null,
    }),

    computed: {
        ...mapState(['isMobile']),

        additionalClasses() {
            const { orientation, theme, hasScrollbar } = this;

            return [orientation, theme, { 'with-scrollbar': hasScrollbar }];
        },

        isSliderVertical() {
            return this.orientation === ORIENTATION_VERTICAL;
        },

        navigationButtons() {
            const { isSliderVertical } = this;

            return {
                prev: {
                    isDisabled: this[
                        isSliderVertical ? 'isBoundedTop' : 'isBoundedLeft'
                    ],

                    direction: -1,
                    icon: isSliderVertical ? ShevronUp : ShevronLeft,
                    class: 'navigation-button-prev',
                },

                next: {
                    isDisabled: this[
                        isSliderVertical ? 'isBoundedBottom' : 'isBoundedRight'
                    ],

                    direction: 1,
                    icon: isSliderVertical ? ShevronDown : ShevronRight,
                    class: 'navigation-button-next',
                },
            };
        },

        verticalNavigationButtonClass() {
            const classes = [];

            if (!this.isSliderVertical) {
                return [];
            }

            if (
                this.slidesRef &&
                this.wrapperScrollHeight - SCROLL_HEIGHT_EPSILON <=
                    this.wrapperVisibleHeight
            ) {
                classes.push('is-hidden');
            }

            return classes;
        },

        isBoundedTop() {
            return approximatelyEqual(this.currentPosition, 0, 5);
        },

        isBoundedBottom() {
            return approximatelyEqual(
                this.wrapperScrollHeight - this.wrapperVisibleHeight,
                this.currentPosition,
                5
            );
        },

        isBoundedLeft() {
            return approximatelyEqual(this.currentPosition, 0, 5);
        },

        isBoundedRight() {
            return approximatelyEqual(
                this.wrapperScrollWidth - this.wrapperVisibleWidth,
                this.currentPosition,
                5
            );
        },

        horizontalSlideChange() {
            return this.changeSlideByOne
                ? this.slideWidth
                : this.wrapperVisibleWidth;
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_ICON_VARIANTS.PRIMARY;
    },

    mounted() {
        this.init();

        this.onResizeFn = debounce(this.resizeHandler, RESIZE_DEBOUNCE);
        this.onScrollFn = debounce(this.calcOnScroll, SCROLL_DEBOUNCE);
        this.onTouchStartFn = debounce(this.onTouchStart, TOUCH_START_DEBOUNCE);
        this.onTouchEndFn = debounce(this.onTouchEnd, TOUCH_END_DEBOUNCE);

        this.attachMutationObserver();

        this.$refs.wrapper.addEventListener('touchstart', this.onTouchStartFn);
        this.$refs.wrapper.addEventListener('touchend', this.onTouchEndFn);

        this.$refs.wrapper.addEventListener('scroll', this.onScrollFn);
        window.addEventListener('resize', this.onResizeFn, false);
    },

    beforeDestroy() {
        this.onResizeFn.cancel();
        this.onScrollFn.cancel();
        this.onTouchStartFn.cancel();
        this.onTouchEndFn.cancel();
        this.observer.disconnect();
        this.$refs.wrapper.removeEventListener(
            'touchstart',
            this.onTouchStartFn
        );
        this.$refs.wrapper.removeEventListener('touchend', this.onTouchEndFn);
        this.$refs.wrapper.removeEventListener('scroll', this.onScrollFn);
        window.removeEventListener('resize', this.onResizeFn, false);
        this.onResizeFn = null;
        this.removeAutoPlay();
    },

    methods: {
        init(reInitSlidesRef = false) {
            if (this.$refs.wrapper.children.length) {
                if (!this.slidesRef || reInitSlidesRef) {
                    this.slidesRef = Array.from(this.$refs.wrapper.children);
                }

                [this.singleSlide] = this.slidesRef;

                this.slideWidth = this.singleSlide.offsetWidth;
                this.slideHeight = this.singleSlide.offsetHeight;
            }

            this.calcOnInit();
            this.initAutoPlay();
        },

        resizeHandler() {
            if (!this.onResizeFn) {
                return;
            }

            this.removeAutoPlay();
            this.init();
        },

        onTouchStart() {
            if (this.hasAutoPlay || !this.hasScrollbar) {
                return;
            }

            if (this.isScrollingTimeout) {
                clearTimeout(this.isScrollingTimeout);
                this.isScrollingTimeout = null;
            }

            this.isScrolling = true;
        },

        onTouchEnd() {
            this.$emit('on-touch-end');

            if (this.hasAutoPlay || !this.hasScrollbar) {
                return;
            }

            if (this.isScrollingTimeout) {
                return;
            }

            this.isScrollingTimeout = setTimeout(() => {
                this.isScrolling = false;
            }, HIDE_SCROLL_AFTER);
        },

        calcOnInit() {
            if (this.isSliderVertical) {
                this.calcWrapperHeight();
                this.calcSlidesHeight();
            } else {
                this.calcWrapperWidth();
                this.calcSlidesWidth();
            }

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        calcOnScroll() {
            if (!this.$refs.wrapper) {
                return;
            }

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        initAutoPlay() {
            if (
                this.isMobile ||
                !this.hasAutoPlay ||
                this.wrapperScrollWidth <= this.wrapperVisibleWidth
            ) {
                if (this.autoPlayInterval) {
                    this.stopAutoPlay();
                    this.removeAutoPlayMouseEvents();
                }

                return;
            }

            this.autoPlayInterval = setInterval(() => {
                if (this.isBoundedRight) {
                    this.scrollTo(0);
                } else {
                    this.changeSlide(1);
                }
            }, this.autoPlayDelay);

            if (this.pauseAutoPlayOnMouseOver) {
                this.addAutoPlayMouseEvents();
            }
        },

        stopAutoPlay() {
            clearInterval(this.autoPlayInterval);
        },

        calcWrapperWidth() {
            this.wrapperScrollWidth = this.$refs.wrapper.scrollWidth;
            this.wrapperVisibleWidth = this.$refs.wrapper.offsetWidth;
        },

        calcSlidesWidth() {
            const childNodes = [...this.$refs.wrapper.childNodes];

            this.slidesWidth = childNodes.map(node => ({
                offsetLeft: node.offsetLeft,
                width: node.offsetWidth,
            }));
        },

        calcWrapperHeight() {
            this.wrapperScrollHeight = this.$refs.wrapper.scrollHeight;
            this.wrapperVisibleHeight = this.$refs.wrapper.offsetHeight;
        },

        calcSlidesHeight() {
            const childNodes = [...this.$refs.wrapper.childNodes];

            this.slidesHeight = childNodes.map(node => ({
                offsetTop: node.offsetTop,
                height: node.offsetHeight,
            }));
        },

        calcActiveSlide() {
            const {
                isSliderVertical,
                slidesHeight,
                slidesWidth,
                currentPosition,
                slidesRef,
            } = this;
            const slides = isSliderVertical ? slidesHeight : slidesWidth;
            let activeSlideIndex = 0;

            const activeIndex = slides.findIndex(slide => {
                return approximatelyEqual(
                    isSliderVertical ? slide.offsetTop : slide.offsetLeft,
                    currentPosition,
                    5
                );
            });

            if (activeIndex !== -1) {
                activeSlideIndex = Math.max(activeIndex, 0);
            }

            if (slidesRef) {
                slidesRef.forEach(child => {
                    child.classList.remove('active');
                });
                slidesRef[activeSlideIndex].classList.add('active');
            }
        },

        calcCurrentPosition() {
            const { scrollTop = 0, scrollLeft = 0 } = this.$refs.wrapper;

            this.currentPosition = this.isSliderVertical
                ? scrollTop
                : scrollLeft;
        },

        onNavigationButtonClick(direction = 1) {
            this.$emit('navigation-button-click', direction);
            this.removeAutoPlay();
            this.changeSlide(direction);
        },

        addAutoPlayMouseEvents() {
            this.$refs.slider.addEventListener('mouseenter', this.stopAutoPlay);
            this.$refs.slider.addEventListener('mouseleave', this.initAutoPlay);
        },

        removeAutoPlayMouseEvents() {
            this.$refs.slider.removeEventListener(
                'mouseenter',
                this.stopAutoPlay
            );
            this.$refs.slider.removeEventListener(
                'mouseleave',
                this.initAutoPlay
            );
        },

        removeAutoPlay() {
            if (this.hasAutoPlay) {
                this.stopAutoPlay();

                if (this.pauseAutoPlayOnMouseOver) {
                    this.removeAutoPlayMouseEvents();
                }
            }
        },

        changeSlide(direction = 1) {
            const offset = this.isSliderVertical
                ? this.slideHeight
                : this.horizontalSlideChange;

            this.scroll(direction * (offset + this.additionalScrollOffset));
        },

        scroll(position = 0) {
            const direction = this.isSliderVertical ? 'top' : 'left';

            this.$refs.wrapper.scrollBy({
                [direction]: position,
                behavior: 'smooth',
            });
        },

        scrollTo(position = 0) {
            const direction = this.isSliderVertical ? 'top' : 'left';

            this.$refs.wrapper.scroll({
                [direction]: position,
                behavior: 'smooth',
            });
        },

        attachMutationObserver() {
            this.observer = new MutationObserver(() => {
                this.init(true);
            });
            this.observer.observe(this.$refs.wrapper, {
                childList: true,
                subtree: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$scrollbar-size: $tailwindcss-spacing-2;

$wrapper-padding-mobile: $tailwindcss-spacing-1;
$wrapper-padding: $tailwindcss-spacing-3;

.simple-slider {
    @apply w-full;

    &.vertical {
        .wrapper {
            @apply flex-col overflow-y-scroll overflow-x-hidden;
            -webkit-overflow-scrolling: touch;
            -webkit-scroll-snap-type: y mandatory;
            scroll-snap-type: y mandatory;
        }

        &:deep() {
            .slider-slide {
                @apply z-2;
            }
        }
    }

    &:not(.with-scrollbar) {
        .wrapper {
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                @apply hidden;
            }
        }
    }

    &.with-scrollbar {
        &.light {
            .wrapper {
                &:hover {
                    &::-webkit-scrollbar-thumb {
                        @apply bg-dark;
                    }

                    &::-webkit-scrollbar-track {
                        @apply bg-gray6;
                    }
                }

                &.is-scrolling {
                    &::-webkit-scrollbar-thumb {
                        @apply bg-dark;
                    }

                    &::-webkit-scrollbar-track {
                        @apply bg-gray6;
                    }
                }
            }
        }

        &.dark {
            .wrapper {
                &:hover {
                    &::-webkit-scrollbar-thumb {
                        @apply bg-light;
                    }

                    &::-webkit-scrollbar-track {
                        @apply bg-gray3;
                    }
                }

                &.is-scrolling {
                    &::-webkit-scrollbar-thumb {
                        @apply bg-light;
                    }

                    &::-webkit-scrollbar-track {
                        @apply bg-gray3;
                    }
                }
            }
        }

        .wrapper {
            &:hover,
            &.is-scrolling {
                &::-webkit-scrollbar-track {
                    @apply bg-gray8;
                }
            }

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                @apply h-2;
            }

            &::-webkit-scrollbar-thumb,
            &::-webkit-scrollbar-track {
                @apply rounded-4 bg-transparent;
            }
        }

        &:not(.vertical) {
            .wrapper {
                padding-bottom: $wrapper-padding-mobile;
            }
        }

        &.vertical {
            .wrapper {
                padding-right: $wrapper-padding-mobile;

                &::-webkit-scrollbar {
                    @apply w-2;
                }
            }
        }
    }

    .slider {
        @apply w-full relative;
    }

    .navigation-button {
        @apply absolute flex z-3;

        &:hover {
            @apply opacity-100;
        }

        &[disabled],
        &.is-hidden {
            @apply hidden;
        }
    }

    .wrapper {
        @apply flex overflow-x-scroll overflow-y-hidden;
        -webkit-overflow-scrolling: touch;
        -webkit-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }

    @screen md {
        &:not(.with-scrollbar) {
            &:not(.vertical) {
                .navigation-button {
                    transform: translateY(-50%);
                }
            }

            &.vertical {
                .navigation-button {
                    transform: translateX(-50%);
                }
            }
        }

        &.with-scrollbar {
            &:not(.vertical) {
                .navigation-button {
                    transform: translateY(-50%)
                        translateY(-0.5 * ($wrapper-padding + $scrollbar-size));
                }

                .wrapper {
                    padding-bottom: $wrapper-padding;
                }
            }

            &.vertical {
                .navigation-button {
                    transform: translateX(-50%)
                        translateX(-0.5 * ($wrapper-padding + $scrollbar-size));
                }

                .wrapper {
                    padding-right: $wrapper-padding;
                }
            }
        }

        &:not(.vertical) {
            .navigation-button {
                @apply top-1/2;
            }

            .navigation-button-prev {
                @apply left-0;
            }

            .navigation-button-next {
                @apply right-0;
            }
        }

        &.vertical {
            .navigation-button {
                @apply left-1/2;
            }

            .navigation-button-prev {
                @apply top-0 bottom-auto;
            }

            .navigation-button-next {
                @apply bottom-0 top-auto;
            }
        }

        .navigation-button {
            @apply flex;
        }
    }
}
</style>
