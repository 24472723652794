<template>
    <component
        :is="tag"
        ref="slide"
        class="slider-slide"
        :class="{
            'with-scroll-snap-on-mobile': scrollSnapOnMobile,
            'with-scroll-snap-on-desktop': scrollSnapOnDesktop,
        }"
        tabindex="0"
    >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'SliderSlide',

    props: {
        /**
         * Custom tag
         */
        tag: {
            type: String,
            default: 'li',
        },

        scrollSnapOnMobile: {
            type: Boolean,
            default: false,
        },

        scrollSnapOnDesktop: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.slider-slide {
    @apply snap-always;

    &:focus {
        @apply outline-none;
    }

    &.with-scroll-snap-on-mobile {
        scroll-snap-align: start;
    }

    &.with-scroll-snap-on-desktop {
        @screen lg {
            scroll-snap-align: start;
        }
    }
}
</style>
